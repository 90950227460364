<template>
  <div id="print" class="user-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      :pagination-page-size="20"
      url="data-statistics/repair-statistics/page">
      <el-button
        v-if="$auth('维修统计导出')"
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="省市地区">
            <vm-district-select
              :province-code.sync="filter.provinceCode"
              :city-code.sync="filter.cityCode"
              :area-code.sync="filter.districtCode">
            </vm-district-select>
          </vm-search>
          <vm-search label="班组名称">
            <el-input v-model="filter.teamName" type="iotDeviceModel"></el-input>
          </vm-search>
          <vm-search label="时间范围">
            <el-date-picker
              v-model="filter.seTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="province" label="省" align="center" width="120"></el-table-column>
      <el-table-column prop="city" label="市" align="center" width="120"></el-table-column>
      <el-table-column prop="district" label="区" align="center" width="120"></el-table-column>
      <el-table-column prop="teamName" label="班组名称" align="center" width="170">
        <template slot="header" slot-scope="">
          班组名称 <el-button type="text" icon="el-icon-sort" @click="sort('teamName')"></el-button>
        </template>
        <template slot-scope="scope">
          <el-button type="text" @click="goToDetail(scope.row)">{{scope.row.teamName}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="orderTotal" label="工单总数" align="center" width="170">
        <template slot="header" slot-scope="">
          工单总数 <el-button type="text" icon="el-icon-sort" @click="sort('orderTotal')"></el-button>
        </template>
        <template slot-scope="scope">
          <el-button type="text" @click="goToTotalDetail(scope.row)">{{scope.row.orderTotal}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="completedCount" label="完成数量" align="center" width="170">
        <template slot="header" slot-scope="">
          完成数量 <el-button type="text" icon="el-icon-sort" @click="sort('completedCount')"></el-button>
        </template>
        <template slot-scope="scope">
          <el-button type="text" @click="goToCompletedDetail(scope.row)">{{scope.row.completedCount}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="noCompletedCount" label="未完成数量" align="center" width="170">
        <template slot="header" slot-scope="">
          未完成数量 <el-button type="text" icon="el-icon-sort" @click="sort('noCompletedCount')"></el-button>
        </template>
        <template slot-scope="scope">
          <el-button type="text" @click="goToNoCompletedDetail(scope.row)">{{scope.row.noCompletedCount}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="completedRate" label="完成率" align="center">
        <template slot="header" slot-scope="">
          完成率 <el-button type="text" icon="el-icon-sort" @click="sort('completedRate')"></el-button>
        </template>
        <template slot-scope="scope">
          {{scope.row.completedRate}}
        </template>
      </el-table-column>
    </vm-table>
    <repair-statistics ref="repairStatistics" @save-success="getList(-1)"></repair-statistics>
    <total-statistics ref="totalStatistics" @save-success="getList(-1)"></total-statistics>
    <completed-statistics ref="completedStatistics" @save-success="getList(-1)"></completed-statistics>
    <no-completed-statistics ref="noCompletedStatistics" @save-success="getList(-1)"></no-completed-statistics>
  </div>
</template>
<script>

  import {export2Excel} from "@/util";
  import print from  "print-js";
  import moment from "moment";
  import RepairStatistics from "./RepairStatistics.vue";
  import TotalStatistics from "./TotalStatistics.vue";
  import CompletedStatistics from "./CompletedStatistics.vue";
  import NoCompletedStatistics from "./NoCompletedStatistics.vue";
  export default {
    components: {RepairStatistics,TotalStatistics,CompletedStatistics,NoCompletedStatistics},
    data() {
      return {
        loading:false,
        filter: {
          name: "",
          maintenanceType:"as_need",
          realEstateName:"",
          arr:[{
            orderColumnName:"completedRate",
            asc:1,
          }],
          orderColumnNames:"completedRate|1",
        },
        senior: false,
        data:[],
        width:200,
        visible:false,
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      handleTime() {
        if(this.filter.seTime !=null) {
          this.filter.createTimeFrom = this.filter.seTime[0];
          this.filter.createTimeTo = this.filter.seTime[1];
        }
      },
      exportExcelData() {
        const title={
          province:"省",
          city:"市",
          district:"区",
          teamName:"班组名称",
          orderTotal:"工单总数",
          completedCount:"完成数量",
          noCompletedCount:"未完成数量",
          completedRate:"完成率",
        };
        this.$http.get("data-statistics/repair-statistics/page",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`维修数据统计-完成率-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      sort(name) {
        if (this.filter.arr.some(item=>item.orderColumnName===name)) {
          const arr = this.filter.arr.map(item=>{
            console.log(item.orderColumnName,name);
            if (item.orderColumnName===name) {
              item.asc=item.asc?0:1;
            }
            return item;
          });
          this.filter.arr=arr;
        }else {
          this.filter.arr.push({
            orderColumnName:name,
            asc:0,
          });
        }
        let str = "";
        this.filter.arr.forEach(item=>{
          str+=item.orderColumnName+"|"+item.asc+"&";
        });
        this.filter.orderColumnNames = str;
        this.getList();
      },
      goToDetail(row) {
        this.$refs.repairStatistics.open(row.teamId);
      },
      goToTotalDetail(row) {
        this.$refs.totalStatistics.open(row.teamId);
      },
      goToCompletedDetail(row) {
        this.$refs.completedStatistics.open(row.teamId);
      },
      goToNoCompletedDetail(row) {
        this.$refs.noCompletedStatistics.open(row.teamId);
      },
      printSomething() {
        print({
          printable:"print",
          type:"html",
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible=false;
      },
      deleteRow(row) {
        this.$confirm(this.$l("common.deleteTip", "确定删除{key}吗？", {key: row.name}), this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/elevator-archive/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },

    },
  };
</script>
<style lang="scss" scoped>
    .treeBox{
        border: 1px solid #E6E6E6;
        padding: 10px;
        margin-right: 10px;
        width: calc(100% - 1300px);
        max-height: 1080px;
        overflow-y: auto;
    }
</style>
