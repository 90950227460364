import { render, staticRenderFns } from "./CompletedStatistics.vue?vue&type=template&id=6226cc3b&scoped=true&"
import script from "./CompletedStatistics.vue?vue&type=script&lang=js&"
export * from "./CompletedStatistics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6226cc3b",
  null
  
)

export default component.exports